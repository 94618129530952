import { render, staticRenderFns } from "./product-badges.vue?vue&type=template&id=5a609ac3&scoped=true&lang=pug&"
import script from "./product-badges.vue?vue&type=script&lang=coffee&"
export * from "./product-badges.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a609ac3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Badges: require('/opt/build/repo/nuxt-app/components/globals/badges.vue').default})













import uniqWith from 'lodash/uniqWith'
import isEqual from 'lodash/isEqual'

export default

	props: product: Object

	methods:

		# check to see if there are multiples of a given
		# option, by grabbing all options & removing duplicates
		# if only 1 item is left, then all the options were the
		# same, and I can assume they don't need a pill in the product card
		hasMultiples: (name) ->
			options = []
			@product.variants.forEach (variant) ->
				results = variant.selectedOptions.find (option) ->
					option.name == name
				options.push results

			uniqueOptions = uniqWith(options, isEqual);
			return true if uniqueOptions.length > 1

		# returns the human readable label and pluralisation
		sizeName: (name) -> "#{@product.variants.length} #{name}s"


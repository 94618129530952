








import { isDark } from '@bkwld/light-or-dark'
import colors from 'library/assets/vars/colors'
export default

	props:
		product: Object
		limit:
			type: Number
			default: 1

	computed:

		# Make the reduced list of badges and map them to be easier to consume
		badges: ->
			@product.categories.filter (category) -> category.isBadge
			.slice 0, @limit
			.map (category) ->
				title: category.badgeText || category.title
				style:
					background: bkgd = category.badgeBackground || colors['primary-color']
					color: if isDark bkgd then 'white' else 'black'


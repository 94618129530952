












import PurchaseOptions from '~/components/globals/product/purchase-options'

export default
	components: { PurchaseOptions }

	props:
		currentVariant: Object

	# focus on the modal's contents when mounted
	mounted: ->
		@$refs.modal.$el.querySelector('.bvm-slot').focus()

	methods:
		closed: ->
			document.querySelector('body').style = ''
			@$nextTick => @$el.remove()



import { render, staticRenderFns } from "./product-card.vue?vue&type=template&id=4d2a1d24&scoped=true&lang=pug&"
import script from "./product-card.vue?vue&type=script&lang=coffee&"
export * from "./product-card.vue?vue&type=script&lang=coffee&"
import style0 from "./product-card.vue?vue&type=style&index=0&id=4d2a1d24&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d2a1d24",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductBadges: require('/opt/build/repo/nuxt-app/components/globals/product/product-badges.vue').default,ProductReviewsBadge: require('/opt/build/repo/nuxt-app/components/globals/product/reviews-badge.vue').default,ProductVisual: require('/opt/build/repo/library/components/globals/product-visual.coffee').default,ProductPrice: require('/opt/build/repo/nuxt-app/components/globals/product/price.vue').default,SmartLink: require('/opt/build/repo/nuxt-app/components/globals/smart-link.coffee').default,Btn: require('/opt/build/repo/nuxt-app/components/globals/btn/btn.vue').default})

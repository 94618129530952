




































































import Options from './card-options'

# subscribe
import { mountComponent } from 'library/helpers/dom'
import SubscribeModal from '~/components/globals/product/subscribe-modal'
import QuickshopModal from '~/components/globals/product/quick-shop'

export default

	components: { Options, SubscribeModal, QuickshopModal }

	# Get the wrapper block's background color, if any
	inject: backgroundColor: default: 'white'

	props:
		product: Object
		subscribe: Boolean
		mobileTwoUp: Boolean # Tightens layout for 2 column grid on mobile

	computed:

		# Root classes
		classes: -> [
			"brand-#{@type}"
			'mobile-two-up': @mobileTwoUp
		]

		hideReviews: -> if @product?.hideReviews? then return @product.hideReviews

		# determine if lucini or cor, for the gradient bg
		type: -> if @product?.vendor?.match /lucini/i then 'lucini' else 'cor'

		btnColor: -> switch @type
			when 'lucini' then 'red'
			when 'cor' then switch @backgroundColor
				when 'light-green' then 'primary'
				else 'light-green'
			else 'light-blue'

		btnFill: ->
			# For light-green bkgs invert the button so it doesn't get lost
			return 'inverted' if @type=='cor' and @backgroundColor=='light-green'
			return 'solid'

		# deeplink url
		productUrl: -> "/products/#{@slug}"

		slug: -> @product.slug || @product.handle

		# what's the current variant (for subscribing)
		currentVariant: -> @product.variants?[0]

		# variant prices for subscription card
		variantPrice: -> @$formatMoney @currentVariant?.price?.amount
		variantDiscountPrice: -> @$formatMoney @currentVariant?.sellingPlanAllocations?[0]?.priceAdjustments?[0]?.price?.amount

		# check to see if the product has categories
		# for example, it wouldn't if the data is coming from
		# the shopify recommendations
		hasCategories: -> @product.categories?.length

	methods:

		showOptionsModal: ->
			await mountComponent SubscribeModal,
				parent: this
				propsData: { currentVariant: @currentVariant }

		showQuickshop: ->
			await mountComponent QuickshopModal,
				parent: this
				propsData: { product: @product}

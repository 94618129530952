














export default

	props: product: Object

	computed:

		# shorthand
		count: -> if @simulate then 10 else @product.reviewsCount?.value
		avg: -> if @simulate then 4.5 else @product.reviewsAverage?.value

		# Whether to show simulated data
		simulate: -> process.env.APP_ENV == 'dev'

